/**
 * @file 全局配置（逐渐废除config_v2.js）
 */
// 产品梳理，一期只保留英文，简体中文，繁体中文，汇率保留USD
// 获取url参数
const getQueryValue = (queryName: string) => {
  var query = decodeURI(window.location.search.substring(1));
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == queryName) { return pair[1]; }
  }
  return null;
};
const globalLang = getQueryValue('passport') === 'hk' ? [] : [
  // {
  //   lang: 'fr-fr',
  //   text: 'Français',
  //   prefix: '$',
  //   suffix: 'USD'
  // },
  // {
  //   lang: 'it-it',
  //   text: 'Italiano',
  //   prefix: '$',
  //   suffix: 'USD'
  // },
  // {
  //   lang: 'de-de',
  //   text: 'Deutsch',
  //   prefix: '$',
  //   suffix: 'USD'
  // },
  // {
  //   lang: 'tr-tr',
  //   text: 'Türkçe',
  //   prefix: '$',
  //   suffix: 'USD'
  // },
  {
    lang: 'vi-vn',
    text: 'Tiếng Việt',
    prefix: '$',
    suffix: 'USD'
  },
  // {
  //   lang: 'pt-pt',
  //   text: 'Português',
  //   prefix: '$',
  //   suffix: 'USD'
  //   // invisible: true
  // },
]
const config: any = {
  orgId: 9001,
  supportLanguages: [
    {
      lang: 'en-us',
      text: 'English',
      prefix: '$',
      suffix: 'USD'
    },
    {
      lang: 'zh-cn',
      text: '简体中文',
      prefix: '￥',
      suffix: 'CNY'
    },
    {
      lang: 'zh-tw',
      text: '繁體中文',
      prefix: 'NT$',
      suffix: 'TWD'
    },
    ...globalLang,
  ],
  supportLanguagesMap: <any>{
    'en-US': {
      lang: 'en-us',
      text: 'English',
      prefix: '$',
      suffix: 'USD',
      zendesk: 'en-us',
    },
    'zh-CN': {
      lang: 'zh-cn',
      text: '简体中文',
      prefix: '￥',
      suffix: 'CNY',
      zendesk: 'zh-cn',
    },
    'zh-TW': {
      lang: 'zh-tw',
      text: '繁體中文',
      prefix: 'NT$',
      suffix: 'TWD',
      zendesk: 'zh-tw',
    },
    'tr-TR': {
      lang: 'tr-tr',
      text: 'Türkçe',
      zendesk: 'tr-tr',
      prefix: '$',
      suffix: 'USD',
    },
    'fr-FR': {
      lang: 'fr-fr',
      text: 'Français',
      zendesk: 'fr-fr',
      prefix: '$',
      suffix: 'USD',
    },
    'it-IT': {
      lang: 'it-it',
      text: 'Italiano',
      zendesk: 'it-it',
      prefix: '$',
      suffix: 'USD',
    },
    'de-DE': {
      lang: 'de-de',
      text: 'Deutsch',
      zendesk: 'de-de',
      prefix: '$',
      suffix: 'USD',
    },
    'vi-VN': {
      lang: 'vi-vn',
      text: 'Tiếng Việt',
      zendesk: 'vi-vn',
      prefix: '$',
      suffix: 'USD',
    },
    'pt-PT': {
      lang: 'pt-pt',
      text: 'Português',
      zendesk: 'pt-pt',
      prefix: '$',
      suffix: 'USD',
    }
    // 'ko-KR': {
    //   lang: 'ko-kr',
    //   text: '한국어',
    //   prefix: '₩',
    //   suffix: 'KRW',
    //   zendesk: 'ko-kr'
    // },

    // 'ru-RU': {
    //   lang: 'ru-ru',
    //   text: 'русский',
    //   prefix: '₽',
    //   suffix: 'RUB',
    //   zendesk: 'ru'
    // },

    // 'ja-JP': {
    //   lang: 'ja-jp',
    //   text: '日本語',
    //   prefix: '￥',
    //   suffix: 'JPY'
    // },

  },
  supportLegal: [
    { prefix: '$', suffix: 'USD' }
    // { prefix: '₽', suffix: 'RUB' },
    // { prefix: '₺', suffix: 'TRY' },
    // { prefix: '￥', suffix: 'CNY' },
    // { prefix: '₩', suffix: 'KRW' },
    // { prefix: 'NT$', suffix: 'TWD' }
  ],
  supportLegalMap: {
    'en-US': { prefix: '$', suffix: 'USD' }
    // 'tr-TR': { prefix: '₺', suffix: 'TRY' },
    // 'ru-RU': { prefix: '₽', suffix: 'RUB' },
    // 'zh-CN': { prefix: '￥', suffix: 'CNY' },
    // 'ko-KR': { prefix: '₩', suffix: 'KRW' },
    // 'zh-TW': { prefix: 'NT$', suffix: 'TWD' }
  },
  copyrightYear: 2025,
};

export default config;

