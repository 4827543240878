<script setup lang="ts">
import { useTitle } from '@vueuse/core';
import { useCommonStore } from '@/store/common';
import { resizeObserver } from '@/utils/tools';
import Layout from '@/components/layout/Layout.vue';
import { useLayoutStore } from '@/store/layout';
import { usePopstate } from '@/hook/usePopstate';
import Loading from '@/components/common/Loading.vue';
import { removeIPQSCookie } from '@/utils/cookie';
import CONST from '@/config/const';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';
import DeviceFingerprint from 'node_js_ipqs_device_tracker';
import { userApi } from '@/config/api';
import { apiHost } from '@/config/setup';

if (!import.meta.env.SSR) {
  const popstate = usePopstate();
  popstate.stateChange((e: any) => {
    const { scroll } = e.state;
    const left = scroll?.left || 0;
    const top = scroll?.top || 0;

    document.documentElement.scrollTo(left, top);
    document.body.scrollTo(left, top);
  });
}

const route = useRoute();
const common = useCommonStore();
const layoutStore = useLayoutStore();
const pageTitle = useTitle();
const { t } = useI18n();

const isLoadingShow = ref(false);

onBeforeMount(() => {
  // 加载完成去除全局的loading
  const openScreenLoadingDom: HTMLElement | null = window?.document?.querySelector('#open-screen-loading');
  if (openScreenLoadingDom) {
    openScreenLoadingDom.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
  }
});

// 获取hvideoid
const getTDHvideoid = data => {
  if (!data) return;
  userApi.getTDInfo({ bizType: 'tdDeviceInfo', params: JSON.stringify({ blackBox: data }) }).then(info => {
    if (info?.code == 200) {
      // 存储hvideoid
      if (info?.data) {
        try {
          const hvideoid = JSON.parse(info.data)?.hvideoid;
          if (hvideoid) localStorage.setItem('tdDeviceInfo_hvideoid', hvideoid);
        } catch {
        }
      }
      if (window._fmOpt) {
        // 10秒轮训
        setTimeout(() => { getTDHvideoid(window._fmOpt.getinfo()); }, 1000 * 15);
      }
    }
  });
};

// 同盾指纹
const initTd = () => {
  window._fmOpt = {
    partner: CONST.TDPartnerCode,
    channel: CONST.TDAppName,
    fpHost: apiHost.tongdunApi,
    timeout: 10000,
    jTimeout: 6000,
    success: function(data) {
      getTDHvideoid(data);
    }
  };
  var fm = document.createElement('script');
  fm.type = 'text/javascript';
  fm.async = true;
  fm.src = '/static/fm.js?t=' + (new Date().getTime() / 3600000).toFixed(0);
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(fm, s);
};

onMounted(() => {
  // 加载完成去除全局的loading
  const openScreenLoadingDom: HTMLElement | null = window?.document?.querySelector('#open-screen-loading');
  if (openScreenLoadingDom) {
    openScreenLoadingDom.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
  }

  resizeObserver((entires: any) => {
    if (entires?.[0]?.contentRect) {
      common.isMobile = entires[0].contentRect.width;
      if (entires[0].contentRect.width < 800) {
        document.documentElement.setAttribute('m', 'true');
      } else {
        document.documentElement.setAttribute('m', 'false');
      }
    }
  }).observe(document.body);
  
  const startTime = Date.now();
  // 同盾指纹
  initTd();
  // ipqs
  DeviceFingerprint.initializeScriptAsync(CONST.ipqsSecretKey)
    .then(async() => {
      // 成功
      // 清楚冗余的device_id相关的cookie
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (name.includes('device_id_')) {
          removeIPQSCookie(name.replace(' ', ''));
        }
      }
      
      DeviceFingerprint.AfterResult((result: any) => {
        // ipqs获取数据成功
        localStorage.setItem('device-info', JSON.stringify({
          'device_id': result?.device_id || '',
          'device_name': result?.operating_system || '',
          'device_model': result?.browser || ''
        }));
        // 向服务器发送ipqs信息
        userApi.submitIPQS(result);
        // ipqs耗时
        const endTime = Date.now();
        userApi.submitRisk({
          bizType: 'ipqsCostTime',
          params: JSON.stringify({
            requestId: result?.request_id || `s_${endTime}${Math.random().toString(32).substr(-4, 4)}`,
            reqTimestamp: startTime,
            resTimestamp: endTime,
            costTime: endTime - startTime
          })
        });
      });
      DeviceFingerprint.Init();
    }).catch((err) => {
      // 失败
      // ipqs耗时
      const endTime = Date.now();
      userApi.submitRisk({
        bizType: 'ipqsCostTime',
        params: JSON.stringify({
          requestId: `f_${endTime}${Math.random().toString(32).substr(-4, 4)}`,
          reqTimestamp: startTime,
          resTimestamp: endTime,
          costTime: endTime - startTime
        })
      });
    });

});

// 初始化firebase
// 国际站firebase
let firebaseConfig = {
  apiKey: "AIzaSyBLbJsBOtzBPgpp7D39_AcCxhYTiQ-TZjQ",
  authDomain: "hashkey-global.firebaseapp.com",
  projectId: "hashkey-global",
  storageBucket: "hashkey-global.appspot.com",
  messagingSenderId: "195398356877",
  appId: "1:195398356877:web:3c6c7fb4c98868a73eb7c4",
  measurementId: "G-E8HMP7F4NR"
};
if (route?.query?.passport == 'hk') {
  // 香港站firebase
  firebaseConfig = {
    apiKey: 'AIzaSyBPkwaf_3rRxuj7vOTGXEcly2r1ZfvoVIk',
    authDomain: 'hashkey-exchange.firebaseapp.com',
    projectId: 'hashkey-exchange',
    storageBucket: 'hashkey-exchange.appspot.com',
    messagingSenderId: '912725861131',
    appId: '1:912725861131:web:5800d002c368dac39ef80e',
    measurementId: 'G-DKW24D00M3'
  };
}
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000;
remoteConfig.defaultConfig = {
  'feature_travel_rule': false  // 控制travelRule
};
fetchAndActivate(remoteConfig)
  .then(() => {
    const feature_travel_rule_web = getValue(remoteConfig, 'feature_travel_rule_web');
    localStorage.setItem('feature_travel_rule_web', feature_travel_rule_web._value);
  })
  .catch(err => {
  // ...
  });

watchEffect(() => {
  if (route.query.f) {
    localStorage.setItem('f', `${route.query.f}`);
  }
});

watch(() => layoutStore.pageTitle, (title: string) => {
  pageTitle.value = title ? `${t(title as string)}` : t('HashKey - 买币更方便，存币更安心');
}, { immediate: true });

watch((): boolean => common.isLoading,
  isLoading => {
    isLoadingShow.value = isLoading;
  }, { immediate: true });

</script>

<template>
  <div v-if="isLoadingShow" class="loadingwrapper">
    <Loading width="100vw" height="100vh" class="loading" />
  </div>
  <Layout v-if="route.meta.ssg" />
  <client-only v-else>
    <Layout />
  </client-only>
</template>

<style lang="scss">
@import 'element-plus/theme-chalk/dark/css-vars.css';
@import '@/assets/styles/entry';
</style>
<style lang="scss" scoped>
.loadingwrapper {
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
}
</style>